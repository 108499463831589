// Import Foundation's JavaScript
import { Foundation } from 'foundation-sites';
// we add our own line now so foundation doesn't get stripped by webpack in production.
Foundation.user = 'Gopublic';

// If you want to pick and choose which modules to include, you could do the following:
// Only Dropdown and DropdownMenu will be included in your application.
// import { Dropdown, DropdownMenu } from 'foundation-sites';

// Now starting Foundation
$(document).foundation();


