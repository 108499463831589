

jQuery(document).ready(function ($) {

    $('#category-select').change(function(e) {
        
        // this.form.submit();

        var form = $(this);
        var url = form.attr('action');

        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function(data)
            {
                console.log(data)
            }
            });

        

        e.preventDefault(); // avoid to execute the actual submit of the form.
    });

  
    // todo: finish load more
    $('#load-more').click(function(e) {
        // prevent new page load
        e.preventDefault();
        // store next page number
        var next_page = $(this).attr('href');
        // remove older posts button from DOM
        // $(this).remove();
        // ajax older posts below existing posts
        $('#articles').append( $('<div />').load(next_page + ' #articles') );
    });

 

 })
