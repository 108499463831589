jQuery(document).ready(function ($) {
                
    $('.bxslider').bxSlider({
        mode: 'fade',
        auto: true,
        easing: 'fade',
        speed   : 1000,
        pager   : false,
        pause   : 9000,
        adaptiveHeight : false,
        nextSelector: '#slider-next',
        prevSelector: '#slider-prev',
        nextText: '',
        prevText: '',
        touchEnabled: false
    });                

    
})