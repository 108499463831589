jQuery(document).ready(function ($) {
                
    $('.bxslider-footer').bxSlider({
        mode: 'fade',
        auto: true,
        easing: 'fade',
        speed   : 1000,
        pager   : false,
        pause   : 9000,
        adaptiveHeight : false,
        nextSelector: '#campagnes-next',
        prevSelector: '#campagnes-prev',
        nextText: '<i class="fas fa-chevron-right"></i>',
        prevText: '<i class="fas fa-chevron-left"></i>', 
     
    });                

    
})